import Loader from "@/components/Loader";
import NavBar from "@/components/NavBar";
import activityModel from "@/models/activity.model";
import Empty from "@/components/Empty";
// @ts-ignore
import { LuckyGrid } from "@lucky-canvas/react";
import { useInfiniteScroll, useReactive, useRequest } from "ahooks";
import { forwardRef, useImperativeHandle, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import { List, PullRefresh, Toast } from "react-vant";
import styles from "./index.module.css";
import dayjs from "dayjs";

const ListView = forwardRef(({ id }: { id: number }, ref) => {
  const { data, loading, loadMoreAsync, reload, reloadAsync, noMore } =
    useInfiniteScroll(
      (d) => {
        return activityModel
          .logs({
            id,
            params: {
              current: d ? d?.current + 1 : 1,
              pageSize: 20,
            },
          })
          .then((res) => res?.data);
      },
      {
        reloadDeps: [id],
        isNoMore: (d) => (d ? !d?.hasMore : true),
      }
    );

  useImperativeHandle(
    ref,
    () => {
      return {
        reload,
      };
    },
    []
  );
  return (
    <PullRefresh onRefresh={reloadAsync}>
      {loading ? (
        <Loader />
      ) : !data || data?.list?.length <= 0 ? (
        <Empty />
      ) : (
        <List
          className={styles.list}
          finished={noMore}
          finishedText="我是有底线的"
          onLoad={async () => {
            await loadMoreAsync();
          }}
        >
          {data?.list?.map((item) => {
            return (
              <div key={item.id} className={styles.item}>
                <div className={styles.status}>{item.log}</div>
                <div className={styles.status}>
                  {dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                </div>
              </div>
            );
          })}
        </List>
      )}
    </PullRefresh>
  );
});

const prizeImg = {
  src: "/images/lottery/grid.png",
  width: "86%",
  height: "86%",
  top: "7%",
  margin: "0 auto",
  borderRadius: "10px",
};

export default () => {
  const myLucky = useRef<any>();
  const listRef = useRef<any>();
  const params = useParams();
  const state = useReactive({
    scrollTop: 0,
    loading: false,
    name: "",
  });
  const { data, refreshAsync } = useRequest(
    () => {
      return activityModel.detail(Number(params.id)).then((res) => res.data);
    },
    {
      ready: !!params.id,
      refreshDeps: [params.id],
    }
  );
  const prizes = useMemo(() => {
    if (!!data?.prizes) {
      return [
        {
          x: 0,
          y: 0,
          borderRadius: "10px",
          imgs: [
            {
              ...prizeImg,
              src: data?.prizes[0]?.image ?? "/images/lottery/grid.png",
            },
          ],
        },
        {
          x: 1,
          y: 0,
          borderRadius: "10px",
          imgs: [
            {
              ...prizeImg,
              src: data?.prizes[1]?.image ?? "/images/lottery/grid.png",
            },
          ],
        },
        {
          x: 2,
          y: 0,
          borderRadius: "10px",
          imgs: [
            {
              ...prizeImg,
              src: data?.prizes[2]?.image ?? "/images/lottery/grid.png",
            },
          ],
        },
        {
          x: 2,
          y: 1,
          borderRadius: "10px",
          imgs: [
            {
              ...prizeImg,
              src: data?.prizes[3]?.image ?? "/images/lottery/grid.png",
            },
          ],
        },
        {
          x: 2,
          y: 2,
          borderRadius: "10px",
          imgs: [
            {
              ...prizeImg,
              src: data?.prizes[4]?.image ?? "/images/lottery/grid.png",
            },
          ],
        },
        {
          x: 1,
          y: 2,
          borderRadius: "10px",
          imgs: [
            {
              ...prizeImg,
              src: data?.prizes[5]?.image ?? "/images/lottery/grid.png",
            },
          ],
        },
        {
          x: 0,
          y: 2,
          borderRadius: "10px",
          imgs: [
            {
              ...prizeImg,
              src: data?.prizes[6]?.image ?? "/images/lottery/grid.png",
            },
          ],
        },
        {
          x: 0,
          y: 1,
          borderRadius: "10px",
          imgs: [
            {
              ...prizeImg,
              src: data?.prizes[7]?.image ?? "/images/lottery/grid.png",
            },
          ],
        },
      ];
    }
    return [];
  }, [data?.prizes]);
  return (
    <div className={styles.page}>
      <NavBar
        theme="none"
        placeholder={false}
        title={state.scrollTop > 100 ? (data ? data.title : "幸运抽奖") : ""}
        onScroll={(e) => (state.scrollTop = e)}
      />
      {!data ? (
        <Loader />
      ) : (
        <>
          <div className={styles.container}>
            <div className={styles.title}># 试试运气吧 #</div>
            <div className={styles.grid}>
              <LuckyGrid
                ref={myLucky}
                width="260px"
                height="260px"
                prizes={prizes}
                buttons={[
                  {
                    x: 1,
                    y: 1,
                    imgs: [
                      {
                        src: "/images/lottery/go.png",
                        width: "100%",
                        height: "100%",
                      },
                    ],
                  },
                ]}
                defaultConfig={{
                  accelerationTime: 300,
                  decelerationTime: 800,
                }}
                activeStyle={{
                  background: "#CE86FA",
                }}
                defaultStyle={{
                  background: "#ECD5FF",
                }}
                onStart={async () => {
                  if (!!state.loading) return;
                  state.loading = true;
                  try {
                    myLucky.current!.play();
                    const res = await activityModel.order({
                      id: params.id,
                      planId: 0,
                      num: 1,
                    });
                    if (res?.code === 200) {
                      state.name = res.data?.name;
                      myLucky.current!.stop(res.data?.index);
                    } else {
                      state.name = "";
                      myLucky.current!.stop(0);
                    }
                  } finally {
                    state.loading = false;
                  }
                }}
                onEnd={() => {
                  if (!!state.name) {
                    Toast(`恭喜抽中: ${state.name}`);
                    state.name = "";
                  }
                  state.loading = false;
                  refreshAsync();
                  listRef?.current?.reload();
                }}
              />
            </div>
            <div className={styles.times}>剩余{data?.times ?? 0}次机会</div>
          </div>
          <div className={styles.section}>中奖记录</div>
          {params.id && <ListView ref={listRef} id={Number(params.id)} />}
        </>
      )}
    </div>
  );
};
